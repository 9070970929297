.discountDetails {
  padding: 24px;
  background: white;


  .wrapper {
    min-height: 400px;
    padding: 0;


    @media screen and (min-width: 992px) {
      padding: 0 50px;
    }
  }

  .PageHeading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    @media screen and (min-width: 480px) {
      align-items: center;
    }

  }

  .heading {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 480px) {
      flex-direction: row;
      gap: 20px;
      align-items: center;
    }
  }


  .status {
    text-transform: capitalize;
    background-color: #52c41a;
    border-radius: 20px;
    padding: 2px 10px;
    color: #fff;
    font-weight: bold;
    width: max-content;
  }

  .row {
    margin-bottom: 8px;
  }
}