.searchInput {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  position: relative;

  @media screen and (min-width: 576px) {
    width: auto;
    margin-top: 0;
  }

  .input {
    width: 100%;
    padding: 8px 32px;
    border: none;
    color: #444;
    background-color: #f4f4f5;
    border-radius: 20px;
    outline: none;
  }

  .searchIcon,
  .clearIcon {
    padding: 8px;
    min-width: 40px;
    position: absolute;

    svg {
      fill: #444;
    }
  }

  .searchIcon {
    left: 0px;
  }

  .clearIcon {
    right: 0px;
  }
}