.block {
  padding: 16px 0px;
  border-bottom: 1px solid #f0f0f0;

  &:hover {
    background-color: #f6f6f6;
  }

  @media screen and (min-width: 480px) {
    display: flex;
    justify-items: center;
    justify-content: space-between;
    padding: 16px 8px;
  }

  .label {
    margin-bottom: 8px;

    @media screen and (min-width: 480px) {
      margin-bottom: 0;
    }

    span {
      font-weight: 600;
    }
  }

  .value {
    color: rgb(46 46 46 / 85%);

    span {
      word-break: break-all;
    }
  }
}