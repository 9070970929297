.productCategories {
  padding: 24px;
  background: white;

  .wrapper {
    min-height: 400px;
    padding: 0;

    @media screen and (min-width: 992px) {
      padding: 0 ;
    }
  }

}