.stockItem {
  padding: 24px;
  background: white;

  .wrapper {
    min-height: 400px;
    padding: 0;

    @media screen and (min-width: 992px) {
      padding: 0;
    }
  }
}

.PageHeading {
  margin-bottom: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn {
    border-radius: 20px !important;
    border: 1px solid transparent;
    background-color: #f4f4f5;
    color: #444;
  }
}

@media screen and (min-width: 576px) {
  .PageHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    gap: 20px;
  }
}
