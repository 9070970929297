.site-layout-background {
  background: #fff;
}

.ant-modal {
  .ant-modal-title {
    font-weight: 700;
    font-size: 20px;
  }
  @media screen and (max-width: 575px) {
    width: 100vw !important;
    max-width: 100vw;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;

    .ant-modal-content {
      border-radius: 0;
      min-height: 100vh;
    }
  }

  .ant-modal-close {
    @media only print {
      display: none;
    }
  }

  .ant-modal-footer {
    @media only print {
      display: none;
    }
  }
}

.ant-btn {
  border-radius: 6px !important;
  font-weight: 500;

  span {
    font-weight: 500;
  }
}

.ant-form-item-label > label {
  font-weight: 500;
}