.HeaderLayout {
  .HeaderLayout__sider {
    border-right: 1px solid #f0f0f0;

    @media only print {
      display: none;
    }
  }

  .HeaderLayout__logo {
    height: 32px;
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 700;
      font-size: 20px;
    }
  }
}

.SiteLayout {
  .SiteLayout__background {
    background: #fff;
  }

  .SiteLayout__header {
    border-bottom: 1px solid #f0f0f0;

    @media only print {
      display: none;
    }

    .trigger {
      padding: 0 24px;
      font-size: 18px;
      line-height: 64px;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: #1890ff;
      }
    }
  }
}
