.customerDetails {
  padding: 24px;
  background: white;

  .wrapper {
    min-height: 400px;
    padding: 0;

    @media screen and (min-width: 480px) {
      padding: 0 24px;
    }

    @media screen and (min-width: 992px) {
      padding: 0 100px;
    }

    .PageHeading,
    .title {
      margin-bottom: 16px;
    }

    .btn {
      border-radius: 20px !important;
      border: 1px solid transparent;
      background-color: #f4f4f5;
      color: #444;
    }

    .details {
      padding: 16px 0px;
      border-bottom: 1px solid #f0f0f0;

      &:hover {
        background-color: #f6f6f6;
      }

      @media screen and (min-width: 480px) {
        padding: 16px 8px;
      }
    }

    .location {
      text-transform: capitalize;
    }

    .address {

      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
    }

    .editIcon,
    .deleteIcon {
      cursor: pointer;
      transition: all 0.3s ease;
    }

    .editIcon:hover {
      color: #47a6ff;
    }

    .deleteIcon:hover {
      color: red;
    }
  }
}